






















import {Component, Prop, Vue} from "vue-property-decorator";
import FileUpload from "@/components/common/FileUpload.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CompanyService from "@/services/CompanyService";
import {namespace} from "vuex-class";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
	methods: {ifValid},
	components: {PortalInput, FileUpload}
})
export default class UploadForCompanyModal extends Vue {

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private onFilesUploaded!: (files: FileMetaDTO[], employerId: number, period: string) => void;

	@Prop()
	private company!: CompanyDTO;

	private period = "";

	private isPeriodEntered = false;

	private next() {
		ifValid(this, () => this.isPeriodEntered = true);
	}

	onUploaded(files: FileMetaDTO[]) {
		this.onFilesUploaded(files, this.company?.id as number, this.period);
		this.$modal.hideAll();
	}


}
